import { fromHex } from "@cosmjs/encoding";
import { DirectSecp256k1Wallet, type DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
import { restoreWallet } from "@sei-js/core";



// Import wallet from a private key or a mnemonic.
export async function importWallet(source: string, type: 'PK' | 'MNEMONIC') {

  const store = useStore();
  let wallet: DirectSecp256k1HdWallet | DirectSecp256k1Wallet;


  if (type === 'MNEMONIC') wallet = await restoreWallet(source);
  if (type === 'PK') wallet = await DirectSecp256k1Wallet.fromKey(fromHex(source), 'sei');

  const [account] = await wallet!.getAccounts();
  if (store.wallets.find((w) => w.address === account.address)) return null;
  return account;

}


export const textEllipsis = (address: string, size = 6) => `${address.slice(0, size)}...${address.slice(-size)}`;
